import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useEmailLanding } from '@/features/signup/email_landing/useEmailLanding';
import { SignupLayout } from '@/features/signup/shared/layout';
import { SignupBoxForm } from '@/features/signup/shared/box';
import {
  SignupLogo,
  SignupSmallTitle,
  SignupSpinner,
  SignupSubtitle,
} from '@/features/signup/shared/components';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { Pages } from '@/webapi/pages';
import { nav } from '@/utils/browser';

export const EmailLandingPage: FC = () => {
  const { loading, error, type, resp } = useEmailLanding();

  const title = useMemo(() => {
    if (isSessionExpired(error, resp)) {
      return `Apologies, But Sign-in link expired`;
    }

    if (error) {
      return `Error`;
    }

    if (type === `signup`) {
      return `Visually.io is setting up your account`;
    }

    return `Securely logging you in...`;
  }, [error, type, resp]);

  const errorMessage = useMemo(() => {
    if (isSessionExpired(error, resp)) {
      return `Sign-in links expires after 2 hours, and can only be used once.`;
    }

    if (error) {
      return `Oops, something went wrong. Please try again later or Sign-in.`;
    }

    return ``;
  }, [error, resp, loading]);

  const onButtonClicked = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    await nav(Pages.LOGIN);
  };

  return (
    <SignupLayout>
      <SignupBoxForm>
        <SignupLogo />
        <VSpace value={2} />
        <SignupSmallTitle>{title}</SignupSmallTitle>
        {error && (
          <>
            <VSpace value={0.1} />
            <SignupSubtitle>{errorMessage}</SignupSubtitle>
          </>
        )}
        <VSpace value={2.1} />
        {error ? (
          <SigninButton
            onClick={onButtonClicked}
            border="none"
            background="#3451FA"
            noTransform
            size="large"
            disabled={loading}
          >
            Sign In
          </SigninButton>
        ) : (
          <SignupSpinner />
        )}
        <VSpace value={1} />
      </SignupBoxForm>
    </SignupLayout>
  );
};

function isSessionExpired(error, resp) {
  if (error && !!resp) {
    if (resp.includes(`expired`)) {
      return true;
    }
  }
  return false;
}

const SigninButton = styled(BigButton)`
  && {
    width: 25rem;
    justify-self: center;
    font-weight: 500;
  }
`;
